import { FC, useCallback, useEffect, useState } from "react";

import { PingFloatingSelectInput, US_STATES } from "@repo/ping-react-js";

import { PVLabelValueEditor } from "features/submission-dashboard/PVLabelValueEditor";
import { PVLabelValueButton } from "features/submission-dashboard/PVLabelValueButton";
import { useUpdateSubmissionTriageMutation } from "services/pvSlice";
import { SovDataType } from "ts-types/DataTypes";

import "./PVInsuredInformationGrid.scss";

type PVInsuredInformationGridProps = {
  selectedItem: SovDataType;
};

export const PVInsuredInformationGrid: FC<PVInsuredInformationGridProps> = ({
  selectedItem,
}) => {
  const [selectedStateIndex, setSelectedStateIndex] = useState(null);

  useEffect(() => {
    setSelectedStateIndex(
      US_STATES.findIndex(
        (option) => option.label === selectedItem.insured_state
      )
    );
  }, [selectedItem]);

  const [updateSubmission] = useUpdateSubmissionTriageMutation();

  const onBlur = useCallback(
    (fieldName: string, newContent: string) => {
      updateSubmission({
        id: selectedItem.id,
        data: {
          [fieldName]: newContent,
        },
      });
    },
    [updateSubmission, selectedItem]
  );

  const onChangeState = useCallback(
    (index: number) => {
      setSelectedStateIndex(index);
      updateSubmission({
        id: selectedItem.id,
        data: {
          insured_state: US_STATES[index].label,
        },
      });
    },
    [updateSubmission, selectedItem]
  );

  return (
    <div className="PVInsuredInformationGrid">
      <PVLabelValueEditor
        label="Name"
        value={selectedItem.insured_name || "Unknown Name"}
        isEditable={true}
        onChange={(newContent) => onBlur("insured_name", newContent)}
      />

      <PVLabelValueEditor
        label="Street"
        value={selectedItem.insured_street || "Unknown Street"}
        isEditable={true}
        onChange={(newContent) => onBlur("insured_street", newContent)}
      />

      <PVLabelValueEditor
        label="Street 2"
        value={selectedItem.insured_street2 || "Unknown Street 2"}
        isEditable={true}
        onChange={(newContent) => onBlur("insured_street2", newContent)}
      />

      <PVLabelValueEditor
        label="City"
        value={selectedItem.insured_city || "Unknown City"}
        isEditable={true}
        onChange={(newContent) => onBlur("insured_city", newContent)}
      />

      <PingFloatingSelectInput
        options={US_STATES}
        selectedIndex={selectedStateIndex}
        setSelectedIndex={onChangeState}
        placement="bottom-end"
        renderLabelElt={(selectedItemLabel, isOpen) => {
          return (
            <PVLabelValueButton
              label="State"
              value={selectedItemLabel || "Unknown State"}
              isActive={isOpen}
            />
          );
        }}
      />

      <PVLabelValueEditor
        label="Home State"
        value={selectedItem.home_state || "Unknown Home State"}
        isEditable={true}
        onChange={(newContent) => onBlur("home_state", newContent)}
      />

      <PVLabelValueEditor
        label="ZIP"
        value={selectedItem.insured_zip || "Unknown ZIP"}
        isEditable={true}
        onChange={(newContent) => onBlur("insured_zip", newContent)}
      />

      <PVLabelValueEditor
        label="Country"
        value={selectedItem.insured_country || "Unknown Country"}
        isEditable={true}
        onChange={(newContent) => onBlur("insured_country", newContent)}
      />

      <PVLabelValueEditor
        label="FEIN"
        value={selectedItem.insured_fein || "Unknown FEIN"}
        isEditable={true}
        onChange={(newContent) => onBlur("insured_fein", newContent)}
      />

      <PVLabelValueEditor
        label="Business Description"
        value={
          selectedItem.insured_business_description || "Unknown Description"
        }
        isEditable={true}
        onChange={(newContent) =>
          onBlur("insured_business_description", newContent)
        }
      />

      <PVLabelValueEditor
        label="Website"
        value={selectedItem.client_website || "Unknown Client Website"}
        isEditable={true}
        onChange={(newContent) => onBlur("client_website", newContent)}
      />

    </div>
  );
};
