import { FC, useCallback, useState } from "react";

import { formatShortDate, initiateDownload } from "@repo/ping-react-js";

import { FileRenameModal } from "components/modals";
import {
  useUpdateSubmissionDocumentMutation,
  useParseSovFileMutation,
  useGetEmailCorrespondenceQuery,
} from "services/pvSlice";
import { previewPdf, previewEmail } from "reducers/inbox";
import { useAppDispatch } from "utils/redux";
import { useAuth } from "utils/hooks";
import {
  ACTION_TYPE,
  DOCUMENT_PROCESSING_STATUS,
  DOCUMENT_TYPE,
  SovDataType,
  SovDataTypeDocument,
} from "ts-types/DataTypes";

import "./PingVisionSubmissionAttachment.scss";

const getProgress = (attachment: SovDataTypeDocument) => {
  if (
    attachment.processing_status === "N" ||
    attachment.processing_status === "C"
  ) {
    return null;
  } else if (attachment.processing_status === "I") {
    let completeness = "unknown";
    if (attachment.processing_pct_complete !== null) {
      completeness = `${attachment.processing_pct_complete}%`;
    }
    return (
      <span>
        <br />
        {DOCUMENT_PROCESSING_STATUS[attachment.processing_status]}:{" "}
        {attachment.processing_last_message} ({completeness})
      </span>
    );
  } else if (attachment.processing_status === "F") {
    return (
      <span>
        <br />
        <strong>Failed: {attachment.processing_last_message}</strong>
      </span>
    );
  } else {
    return null;
  }
};

type PingVisionSubmissionAttachmentsProps = {
  submission: SovDataType;
};

//

export const PingVisionSubmissionAttachments: FC<
  PingVisionSubmissionAttachmentsProps
> = ({ submission }) => {
  // If submission has an attached email thread, we filter out the extra files
  // created as a result of parsing the email. We only keep the EML file.
  // Eventually, emails should probably live in a separate section. They
  // shouldn't be lumped in with all the other attachments like this.
  let attachmentsWithoutEmail = submission.documents;
  if (doesSubmissionHaveParsedEmailDocs(submission)) {
    attachmentsWithoutEmail = submission.documents.filter(
      (doc) =>
        doc.document_type !== "EMAIL_BODY_HTML" &&
        doc.document_type !== "EMAIL_BODY_TXT"
    );
  }

  const archivedAttachments = attachmentsWithoutEmail.filter(
    (attachment) => attachment.is_archived
  );

  const nonArchivedAttachments = attachmentsWithoutEmail.filter(
    (attachment) => !attachment.is_archived
  );

  return (
    <ul className="PingVisionSubmissionAttachments">
      {nonArchivedAttachments.map((attachment, i) => (
        <PingVisionSubmissionAttachment
          key={i}
          attachment={attachment}
          pingid={submission.id}
          isEmail={attachment.document_type === "EML"}
        />
      ))}
      {archivedAttachments.length > 0 && <li>View archived files (TODO)</li>}
    </ul>
  );
};

type PingVisionSubmissionAttachmentProps = {
  attachment: SovDataTypeDocument;
  pingid: string;
  isEmail?: boolean;
};

const PingVisionSubmissionAttachment: FC<
  PingVisionSubmissionAttachmentProps
> = ({ attachment, pingid, isEmail = false }) => {
  const dispatch = useAppDispatch();
  const { accessToken } = useAuth();
  const [updateSubmissionDocument] = useUpdateSubmissionDocumentMutation();
  const [parseSovFile] = useParseSovFileMutation();
  const [openRenameModal, setOpenRenameModal] = useState(false);

  const { data, isFetching } = useGetEmailCorrespondenceQuery(
    { sovid: pingid },
    { skip: !isEmail }
  );

  const handleUpdateDocument = useCallback(
    async (attachment, newname) => {
      try {
        await updateSubmissionDocument({
          id: pingid,
          filename: attachment.filename,
          data: { filename: newname },
        });
        // You might want to add some state update or notification here
        console.log("Document updated successfully");
      } catch (error) {
        console.error("Error updating document:", error);
        // Handle error (e.g., show an error message to the user)
      }
    },
    [pingid, updateSubmissionDocument]
  );

  const onPreview = useCallback(
    (url: string) => {
      if (isEmail) {
        dispatch(previewEmail(pingid));
      } else {
        dispatch(previewPdf(url));
      }
    },
    [dispatch, isEmail, pingid]
  );

  const onActionClick = useCallback(
    async (action: string) => {
      if (action === "download") {
        initiateDownload(attachment.url, accessToken, attachment.filename);
      } else if (action === "rename") {
        const newname = prompt("Enter new name", attachment.filename);
        await updateSubmissionDocument({
          id: pingid,
          filename: attachment.filename,
          data: { filename: newname },
        });
      } else if (action === "sovfixer-parse") {
        await parseSovFile({ id: pingid, filename: attachment.filename });
      } else if (action === "archive") {
        await updateSubmissionDocument({
          id: pingid,
          filename: attachment.filename,
          data: { is_archived: true },
        });
      } else if (action === "unarchive") {
        await updateSubmissionDocument({
          id: pingid,
          filename: attachment.filename,
          data: { is_archived: false },
        });
      } else if (action === "preview") {
        onPreview(attachment.url);
      } else {
        alert("Unknown action: " + action);
        throw new Error(`Unknown action: ${action}`);
      }
    },
    [
      attachment.url,
      attachment.filename,
      accessToken,
      updateSubmissionDocument,
      pingid,
      parseSovFile,
      onPreview,
    ]
  );

  const docitem = (
    <span>
      {`${formatShortDate(attachment.created_time)}: `}
      {attachment.document_type &&
        DOCUMENT_TYPE[attachment.document_type] &&
        `${DOCUMENT_TYPE[attachment.document_type]}: `}
      {isFetching || !isEmail ? attachment.filename : data?.[0]?.subject}
    </span>
  );

  let allActions = attachment.actions.concat(["rename"]);
  if (isAttachmentPDF(attachment) || isEmail) {
    allActions = allActions.concat(["preview"]);
  }

  const actionButtons = allActions.map((action) => {
    return (
      <span
        className="PingVisionSubmissionAttachment__Actions__Action"
        key={action}
        onClick={() => onActionClick(action)}
      >
        {ACTION_TYPE[action] || action}
      </span>
    );
  });

  const actions = (
    <span className="PingVisionSubmissionAttachment__Actions">
      [{" "}
      {actionButtons.map((action, i) => (
        <span key={i}>
          {action}
          {i < actionButtons.length - 1 ? " | " : ""}
        </span>
      ))}{" "}
      ]
    </span>
  );

  const progress = getProgress(attachment);

  return (
    <>
      <FileRenameModal
        isOpen={openRenameModal}
        onClose={() => {
          setOpenRenameModal(false);
        }}
        onClickOk={(newName) => {
          handleUpdateDocument(attachment, newName);
          setOpenRenameModal(false);
        }}
      />
      <li className="PingVisionSubmissionAttachment">
        {docitem} {actions} {progress}
      </li>
    </>
  );
};

const isAttachmentPDF = (attachment: SovDataTypeDocument) =>
  attachment.filename.toLowerCase().endsWith(".pdf");

const doesSubmissionHaveParsedEmailDocs = (submission: SovDataType) => {
  const hasEmailTxt = submission.documents.some(
    (doc) => doc.document_type === "EMAIL_BODY_TXT"
  );
  const hasEmailHtml = submission.documents.some(
    (doc) => doc.document_type === "EMAIL_BODY_HTML"
  );
  const hasEmlFile = submission.documents.some(
    (doc) =>
      doc.document_type === "EML" &&
      doc.filename.toLocaleLowerCase().endsWith(".eml")
  );

  return hasEmailTxt && hasEmailHtml && hasEmlFile;
};
