import {
  ButtonHTMLAttributes,
  FC,
  MouseEventHandler,
  useCallback,
} from "react";
import cx from "classnames";

import "./PVLabelValueButton.scss";

type PVLabelValueButtonProps = {
  label: string;
  value: string;
  isLoading?: boolean;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const PVLabelValueButton: FC<PVLabelValueButtonProps> = ({
  label,
  value,
  isLoading,
  isActive,
  onClick,
  ...restProps
}) => {
  const appliedClasses = cx("PVLabelValueButton", {
    "PVLabelValueButton--IsLoading": isLoading,
    "PVLabelValueButton--IsActive": isActive,
  });

  const onClickSafe = useCallback(
    (e) => {
      if (isLoading) {
        e.preventDefault();
        return;
      }

      if (onClick) {
        onClick(e);
      }
    },
    [isLoading, onClick]
  );

  return (
    <button className={appliedClasses} onClick={onClickSafe} {...restProps}>
      <div className="PVLabelValueButton__Key">
        <div className="PVLabelValueButton__Key__Text">{label}</div>
      </div>
      <div className="PVLabelValueButton__Value">{value}</div>
    </button>
  );
};
