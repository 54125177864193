import { formatDistance, formatDistanceToNow } from "date-fns";

export const formatShortDateTime = (isoString: string | null): string => {
  if (!isoString) {
    return "";
  }
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
    date
  );

  return formattedDateTime.replace(",", " at");
};

export const formatShortDate = (isoString: string | null): string => {
  if (!isoString) {
    return "";
  }
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
    dateStyle: "short",
  };
  return new Intl.DateTimeFormat(undefined, options).format(date);
};


export const formatLongDate = (isoString: string | null): string => {
  if (!isoString) {
    return "";
  }
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
    date
  );

  return formattedDateTime;
};

export function formatDateTimeStringWithAgo(value: string) {
  const date = new Date(value);
  return formatDistanceToNow(date, { addSuffix: true }).replace("about ", "");
}

export function formatDurationWithAgo(startTime: string, endTime: string) {
  if (!startTime || !endTime) {
    return null;
  }
  const start = new Date(startTime);
  const end = new Date(endTime);
  const duration = formatDistance(end, start, {
    includeSeconds: true,
  });
  return duration;
}
