import React, { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";

const VERBOSE = false;

type RealTimeUpdatesProps = {
  baseUrl: string;
  accessToken: string;
  onUpdate: (data: any) => void;
};

const RealTimeUpdates: React.FC<RealTimeUpdatesProps> = ({
  onUpdate,
  baseUrl,
  accessToken,
}) => {
  const [lastMessageState, setLastMessageState] = useState<MessageEvent | null>(
    null
  );

  const { lastMessage, readyState } = useWebSocket(
    `${baseUrl}?token=${accessToken}`,
    {
      onOpen: () => VERBOSE && console.info("WebSocket connection established"),
      onClose: () => {
        if (VERBOSE) {
          console.info("WebSocket connection closed");
        }
        setLastMessageState(null);
      },
      onError: (error) => VERBOSE && console.error("WebSocket error:", error),
      shouldReconnect: () => true,
    }
  );

  useEffect(() => {
    if (lastMessage !== null) {
      setLastMessageState(lastMessage);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (lastMessageState !== null && readyState === 1) {
      if (VERBOSE) {
        console.info(
          "Received event:",
          readyState,
          JSON.parse(lastMessageState.data)
        );
      }
      onUpdate(JSON.parse(lastMessageState.data));
    }
  }, [lastMessageState, readyState]);

  return null;
};

export default RealTimeUpdates;
