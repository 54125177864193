import cx from "classnames";

import { PingSelectOptions } from "../../ts-types/InputTypes";

import "./PingSelectInput.scss";

type PingSelectInputProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  options: PingSelectOptions;
  className?: string;
  allowEmpty?: boolean;
};

export const PingSelectInput = ({
  options,
  className,
  allowEmpty = false,
  ...restProps
}: PingSelectInputProps) => {
  if (allowEmpty) {
    options = [{ label: "Select", value: "" }, ...options];
  }

  const optionElts = options.map((o) => (
    <option key={o.value} value={o.value}>
      {o.label}
    </option>
  ));

  const appliedClasses = cx("PingSelectInput", className);

  return (
    <div className={appliedClasses}>
      <select className="PingSelectInput__Select" {...restProps}>
        {optionElts}
      </select>
    </div>
  );
};
