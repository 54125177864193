import { FC, useCallback, useEffect, useState } from "react";

import { PVLabelValueEditor } from "features/submission-dashboard/PVLabelValueEditor";
import { SovDataType } from "ts-types/DataTypes";

import "./PVOverviewGrid.scss";
import { useUpdateSubmissionTriageMutation } from "src/services/pvSlice";

type PVOverviewGridProps = {
  selectedItem: SovDataType;
};

export const PVOverviewGrid: FC<PVOverviewGridProps> = ({ selectedItem }) => {
  const [selectedStateIndex, setSelectedStateIndex] = useState(null);

  const [updateSubmission] = useUpdateSubmissionTriageMutation();

  const onBlur = useCallback(
    (fieldName: string, newContent: string) => {
      updateSubmission({
        id: selectedItem.id,
        data: {
          [fieldName]: newContent,
        },
      });
    },
    [updateSubmission, selectedItem]
  );

  return (
    <div className="PVOverviewGrid">
      <PVLabelValueEditor
        label="Inception Date"
        value={selectedItem.inception_date || "n/a"}
        isEditable={true}
        onChange={(newContent) => onBlur("inception_date", newContent)}
      />

      <PVLabelValueEditor
        label="Expiration Date"
        value={selectedItem.expiration_date || "n/a"}
        isEditable={true}
        onChange={(newContent) => onBlur("expiration_date", newContent)}
      />

      <PVLabelValueEditor
        label="Days to Inception (DTI)"
        value={selectedItem.days_until_inception || "n/a"}
        isEditable={false}
        onChange={(newContent) => onBlur("days_until_inception", newContent)}
      />

      {/* <PVLabelValueEditor
        label="Sender Email"
        value={selectedItem.from_email || "Unknown Sender"}
        isEditable={false}
        onChange={(newContent) => onBlur("from_email", newContent)}
      /> */}

      <PVLabelValueEditor
        label="Client Reference ID"
        value={selectedItem.client_ref || "n/a"}
        isEditable={true}
        onChange={(newContent) => onBlur("client_ref", newContent)}
      />
    </div>
  );
};
