import { FC, useCallback, useMemo } from "react";

import {
  PingPdfPreview,
  PingEmailPreview,
  PingCircleIconButton,
} from "@repo/ping-react-js";

import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import { clearPreview } from "reducers/inbox";
import { useAppDispatch, useAppSelector } from "utils/redux";
import { getApiBaseUrl } from "src/utils";
import { useGetEmailCorrespondenceQuery } from "services/pvSlice";

import "./PVDocumentPreviewPanel.scss";

type PVDocumentPreviewPanelProps = {
  sovid: string;
};

export const PVDocumentPreviewPanel: FC<PVDocumentPreviewPanelProps> = ({
  sovid,
}) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const documentPreviewType = useAppSelector(
    (state) => state.inbox.documentPreview?.type
  );
  const documentPreviewUrl = useAppSelector((state) =>
    state.inbox.documentPreview?.type === "PDF"
      ? state.inbox.documentPreview?.url
      : null
  );
  const downloadUrl = documentPreviewUrl
    ? new URL(documentPreviewUrl, getApiBaseUrl()).toString()
    : null;

  const { data: email, isFetching } = useGetEmailCorrespondenceQuery(
    { sovid },
    { skip: documentPreviewType !== "EMAIL" }
  );

  const documentOptions = useMemo(
    () => ({
      httpHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
    [accessToken]
  );

  const onClose = useCallback(() => {
    dispatch(clearPreview());
  }, [dispatch]);

  return (
    <div className="PVDocumentPreviewPanel">
      {documentPreviewType === "PDF" && downloadUrl && (
        <PingPdfPreview
          file={downloadUrl}
          documentOptions={documentOptions}
          onClose={onClose}
        />
      )}
      {documentPreviewType === "EMAIL" && !isFetching && email && (
        <PingEmailPreview content={email} onClose={onClose} />
      )}
      {documentPreviewType === "EMPTY" && (
        <div className="PVDocumentPreviewPanel__Empty">
          <PingCircleIconButton iconName="close" onClick={onClose} />
          <PVEmptyPanelMessage
            className="PVDocumentPreviewPanel__Empty__Message"
            message="Select an attachment to preview from the attachments list"
          />
        </div>
      )}
    </div>
  );
};
