import { FC, ReactNode } from "react";

import "./PVPanel.scss";

type PVPanelProps = {
  children?: ReactNode;
};

export const PVPanel: FC<PVPanelProps> = ({ children }) => {
  return <div className="PVPanel">{children}</div>;
};
