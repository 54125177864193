import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseQueryWithZodValidation } from "@repo/ping-react-js";

import { getApiBaseUrl } from "../utils";
import { PING_VISION_DEFAULT_GRID_PAGE_SIZE } from "constants/ApiConstants";
import type { RootState } from "services/store";
import {
  type NavigationResponse,
  type ActivityItemType,
  type EmailCorrespondenceResponse,
  emailCorrespondenceResponseSchema,
} from "ts-types/ApiTypes";
import {
  SovDataTypePaginatedResponse,
  sovDataTypePaginatedResponseSchema,
} from "ts-types/DataTypes";

export const api = createApi({
  baseQuery: baseQueryWithZodValidation(
    fetchBaseQuery({
      baseUrl: getApiBaseUrl(),
      prepareHeaders: (headers, { getState, endpoint }) => {
        if (endpoint !== "uploadDocument") {
          headers.set("Content-Type", "application/json");
        }
        const state = getState() as RootState;
        const accessToken = state.auth.accessToken;
        if (accessToken && !headers.has("Authorization")) {
          headers.set("Authorization", `Bearer ${accessToken}`);
        }

        return headers;
      },
    })
  ),

  tagTypes: ["PVSubmissionsList", "PVNotes"],

  endpoints: (build) => ({
    getEnvironment: build.query<any, void>({
      query: () => ({
        url: `api/v1/environment`,
        method: "GET",
      }),
    }),
    getSubmissions: build.query<
      SovDataTypePaginatedResponse,
      {
        fields?: string[];
        search?: string | null;
        advancedSearchFields?: Record<string, string> | null;
        cursorId?: string | null;
        orgShortName?: string | null;
      }
    >({
      query: ({ fields, search, advancedSearchFields }) => {
        const searchParams = new URLSearchParams();

        // searchParams.append("exclude_system_tester", "true");

        searchParams.append(
          "page_size",
          PING_VISION_DEFAULT_GRID_PAGE_SIZE.toString()
        );

        searchParams.append("fields", fields?.join(",") || "");

        if (search) {
          searchParams.append("search", search);
        }

        if (advancedSearchFields) {
          Object.entries(advancedSearchFields).forEach(([key, value]) => {
            searchParams.append(key, value);
          });
        }

        return {
          url: `api/v1/submission?${searchParams.toString()}`,
          method: "GET",
        };
      },
      providesTags: () => [{ type: "PVSubmissionsList", id: "LIST" }],
      extraOptions: {
        dataSchema: sovDataTypePaginatedResponseSchema,
      },
    }),
    uploadDocument: build.mutation({
      query: ({ accessToken, id, file }) => ({
        url: `api/v1/submission/${id}/document`,
        method: "POST",
        body: file,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    bulkUpdateSubmission: build.mutation({
      query: ({ ids, changes }) => ({
        url: `api/v1/submission/bulkupdate`,
        method: "POST",
        body: { ids, changes },
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    changeSubmissionTriageStatus: build.mutation<
      any,
      { id: string; status: string }
    >({
      query: ({ id, status }) => ({
        url: `api/v1/submission/${id}/change_status/`,
        method: "PATCH",
        body: { workflow_status_id: status },
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    // used for rename, document type change, and archiving.
    updateSubmissionDocument: build.mutation<
      any,
      { id: string; filename: string; data: Record<string, any> }
    >({
      query: ({ id, filename, data }) => ({
        url: `api/v1/submission/${id}/document/${filename}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    // used for rename, document type change, and archiving.
    parseSovFile: build.mutation<any, { id: string; filename: string }>({
      query: ({ id, filename }) => ({
        url: `api/v1/submission/${id}/document/${filename}/sovfixer-parse`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    updateSubmissionTriage: build.mutation<
      any,
      { id: string; data: Record<string, any> }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/submission/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    getSubmissionHistory: build.query<ActivityItemType[], { id: string }>({
      query: ({ id }) => ({
        url: `api/v1/submission/${id}/history`,
        method: "GET",
      }),
      providesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    getNav: build.query<NavigationResponse, any>({
      query: () => ({
        url: `api/v1/nav`,
        method: "GET",
      }),
    }),
    getSettings: build.query<any, any>({
      query: () => ({
        url: `api/v1/settings`,
        method: "GET",
      }),
    }),
    getEmailCorrespondence: build.query<
      EmailCorrespondenceResponse,
      { sovid: string }
    >({
      query: ({ sovid }) => ({
        url: `api/v1/submission/${sovid}/correspondence`,
      }),
      extraOptions: {
        dataSchema: emailCorrespondenceResponseSchema,
      },
    }),
    getNotesForSubmission: build.query<any, { id: string | null }>({
      query: ({ id }) => ({
        url: `api/v1/submission/note/?submission_id=${id}`,
        method: "GET",
      }),
      providesTags: ["PVNotes"],
    }),
    createNote: build.mutation({
      query: ({ id, text }) => ({
        url: `api/v1/submission/note/`,
        method: "POST",
        body: { submission: id, text: text },
      }),
      invalidatesTags: ["PVNotes"],
    }),
  }),
});

export const {
  useCreateNoteMutation,
  useGetNotesForSubmissionQuery,
  useBulkUpdateSubmissionMutation,
  useGetNavQuery,
  useUploadDocumentMutation,
  useGetSubmissionHistoryQuery,
  useGetSubmissionsQuery,
  useChangeSubmissionTriageStatusMutation,
  useUpdateSubmissionTriageMutation,
  useGetEnvironmentQuery,
  useGetSettingsQuery,
  useUpdateSubmissionDocumentMutation,
  useParseSovFileMutation,
  useGetEmailCorrespondenceQuery,
} = api;
