import { useState, useCallback, useMemo } from "react";
import { useAppSelector } from "utils/redux";
import { useBulkUpdateSubmissionMutation } from "services/pvSlice";
import { PingVisionModal } from "../../components/modals/PingVisionModal";

import "./PingCommandMenu.scss";

interface CommandOption {
  icon: string;
  label: string;
  shortcut: string;
  command: string;
  pk?: number;
}

const commandOptions: CommandOption[] = [
  { icon: "👤", label: "Assign to...", shortcut: "A", command: "assign" },
  { icon: "🔄", label: "Change status...", shortcut: "S", command: "status" },
];

type PingCommandMenuProps = {
  onClose: () => void;
};

const mergeCommonTransitions = (filteredSelectedSovs) => {
  if (!filteredSelectedSovs || filteredSelectedSovs.length === 0) {
    return {};
  }

  // Initialize with the transitions from the first SOV
  let commonTransitions = { ...filteredSelectedSovs[0].actions.transition_to };

  // Iterate through the rest of the SOVs
  for (let i = 1; i < filteredSelectedSovs.length; i++) {
    const currentTransitions = filteredSelectedSovs[i].actions.transition_to;

    // Keep only the transitions that exist in both commonTransitions and currentTransitions
    commonTransitions = Object.keys(commonTransitions).reduce(
      (acc, statusId) => {
        if (
          Object.prototype.hasOwnProperty.call(currentTransitions, statusId)
        ) {
          acc[statusId] = commonTransitions[statusId];
        }
        return acc;
      },
      {}
    );

    // If no common transitions left, break early
    if (Object.keys(commonTransitions).length === 0) {
      break;
    }
  }

  return commonTransitions;
};

export const PingCommandMenu = ({ onClose }: PingCommandMenuProps) => {
  const settings = useAppSelector((state) => state.settings.settings);
  const selectedSubmissions = useAppSelector(
    (state) => state.settings.selectedSubmissions
  );

  const sovs = useAppSelector((state) => state.inbox.sovs);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const [bulkUpdateSubmission] = useBulkUpdateSubmissionMutation();

  const onBulkChangeClaimById = useCallback(
    async (claimedById: number) => {
      await bulkUpdateSubmission({
        ids: selectedSubmissions,
        changes: [
          {
            action: "claim",
            parameters: { claimed_by_id: claimedById },
          },
        ],
      });
    },
    [bulkUpdateSubmission, selectedSubmissions]
  );

  const onBulkChangeStatus = useCallback(
    async (newStatusId: number) => {
      await bulkUpdateSubmission({
        ids: selectedSubmissions,
        changes: [
          {
            action: "change_status",
            parameters: { workflow_status_id: newStatusId },
          },
        ],
      });
    },
    [bulkUpdateSubmission, selectedSubmissions]
  );

  const assigneeOptions = useMemo(() => {
    return (
      settings?.teams?.[0]?.users?.map((user) => ({
        icon: "",
        label: user.username,
        shortcut: "",
        command: "",
        pk: user.id,
      })) || []
    );
  }, [settings]);

  const statusOptions = useMemo(() => {
    const filteredSelectedSovs = sovs?.filter((sov) =>
      selectedSubmissions?.includes(sov.id)
    );

    const commonTransitions = mergeCommonTransitions(filteredSelectedSovs);
    return Object.keys(commonTransitions).map((statusId) => ({
      icon: "",
      label: commonTransitions[statusId],
      shortcut: "",
      command: "",
      pk: parseInt(statusId),
    }));
  }, [sovs, selectedSubmissions]);

  const options = commandOptions.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const assignContent = useMemo(() => {
    return (
      <ul className="command-list">
        {assigneeOptions.map((option, index) => (
          <li
            key={index}
            className="command-item"
            onClick={() => {
              onBulkChangeClaimById(option.pk);
              onClose();
            }}
          >
            <span className="icon">{option.icon}</span>
            <span className="label">{option.label}</span>
            <span className="shortcut">{option.shortcut}</span>
          </li>
        ))}
      </ul>
    );
  }, [assigneeOptions, onBulkChangeClaimById, onClose]);

  const statusContent = useMemo(() => {
    return (
      <ul className="command-list">
        {statusOptions.map((option, index) => (
          <li
            key={index}
            className="command-item"
            onClick={() => {
              onBulkChangeStatus(option.pk);
              onClose();
            }}
          >
            <span className="icon">{option.icon}</span>
            <span className="label">{option.label}</span>
            <span className="shortcut">{option.shortcut}</span>
          </li>
        ))}
      </ul>
    );
  }, [statusOptions, onBulkChangeStatus, onClose]);

  return (
    <div className="ping-command-menu">
      <div className="issues-count">{`${selectedSubmissions?.length} items selected`}</div>
      <input
        type="text"
        className="search-input"
        placeholder="Type a command or search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {selectedItem?.command === "assign" && assignContent}
      {selectedItem?.command === "status" && statusContent}
      {!selectedItem?.command && (
        <ul className="command-list">
          {options.map((option, index) => (
            <li
              key={index}
              className="command-item"
              onClick={() => {
                setSelectedItem(option);
              }}
            >
              <span className="icon">{option.icon}</span>
              <span className="label">{option.label}</span>
              <span className="shortcut">{option.shortcut}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const PingCommandMenuModal = ({ isOpen, onClose }) => {
  return (
    <PingVisionModal
      className="PingCommandMenuModal"
      isOpen={isOpen}
      onClose={onClose}
      title={null}
      contentOnly={true}
      renderFooter={() => <></>}
      renderContent={() => (
        <>
          <PingCommandMenu onClose={onClose} />
        </>
      )}
    />
  );
};
