import { FC, useCallback, useMemo, useState } from "react";
import cx from "classnames";

import { PingFloatingSelectInput, PingMaterialIcon } from "@repo/ping-react-js";

import { PAPERTRAIL, S3_RESULTS_BUCKET } from "constants/SubmissionConstants";
import {
  useBulkUpdateSubmissionMutation,
  useChangeSubmissionTriageStatusMutation,
} from "services/pvSlice";
import { useGetSubmissionList } from "features/submission-dashboard/queries";
import { useAppSelector } from "utils/redux";
import { SovDataType } from "ts-types/DataTypes";

import "./PRSubmissionDetailsToolbar.scss";

type PRSubmissionDetailsToolbarProps = {
  selectedItem: SovDataType;
};

export const PRSubmissionDetailsToolbar: FC<
  PRSubmissionDetailsToolbarProps
> = ({ selectedItem }) => {
  const mapsReady = selectedItem?.ping_maps?.status === "R";

  // We use this to show the loading state on the correct button when the
  // submissions list is being fetched. If we don't keep track of this, there's
  // no way to know which button triggered the loading state for the submission
  // list.
  const [lastAction, setLastAction] = useState<
    "assigneeChange" | "submissionChange" | null
  >(null);

  const [changeSubmissionStatus, { isLoading: isLoadingChangeStatusRequest }] =
    useChangeSubmissionTriageStatusMutation();

  const { isFetching: isLoadingSubmissionList } = useGetSubmissionList();

  const statusOptions = useMemo(() => {
    const options = Object.entries(selectedItem.actions.transition_to).map(
      ([value, label]) => ({ value, label })
    );
    return [
      {
        value: selectedItem.workflow_status__name,
        label: selectedItem.workflow_status__name,
      },
      ...options,
    ];
  }, [selectedItem.actions.transition_to, selectedItem.workflow_status__name]);

  const onChangeStatus = useCallback(
    async (index: number) => {
      setLastAction("submissionChange");
      const status = statusOptions[index].value;
      await changeSubmissionStatus({ id: selectedItem.id, status });
    },
    [changeSubmissionStatus, selectedItem.id, statusOptions]
  );

  const settings = useAppSelector((state) => state.settings.settings);

  const currentAssignee = useMemo(() => {
    return settings?.users?.find(
      (user) => user.id === selectedItem.claimed_by_id
    )?.username;
  }, [settings, selectedItem.claimed_by_id]);

  const assigneeOptions = useMemo(() => {
    const selected = settings?.users?.find(
      (user) => user.id === selectedItem.claimed_by_id
    );

    const options =
      settings?.teams?.[0]?.users
        ?.filter((user) => user.id !== selectedItem.claimed_by_id)
        ?.map((user) => ({
          value: user.id,
          label: user.username,
        })) || [];

    return [{ label: selected?.username, value: selected?.id }, ...options];
  }, [selectedItem.claimed_by_id, settings]);

  const [bulkUpdateSubmission, { isLoading: isLoadingBulkSubmission }] =
    useBulkUpdateSubmissionMutation();

  const onChangeAssignee = useCallback(
    async (index: number) => {
      setLastAction("assigneeChange");
      const claimedById = assigneeOptions[index].value;

      await bulkUpdateSubmission({
        ids: [selectedItem.id],
        changes: [
          {
            action: "claim",
            parameters: { claimed_by_id: claimedById },
          },
        ],
      });
    },
    [bulkUpdateSubmission, selectedItem.id, assigneeOptions]
  );

  return (
    <div className="PRSubmissionDetailsToolbar">
      <PingFloatingSelectInput
        options={assigneeOptions}
        selectedIndex={0}
        setSelectedIndex={onChangeAssignee}
        placement="bottom-end"
        renderLabelElt={(_, isOpen) => {
          return (
            <PRToolbarButton
              label={currentAssignee || "Unassigned"}
              iconName="account_circle"
              isActive={isOpen}
              isLoading={
                isLoadingBulkSubmission ||
                (isLoadingSubmissionList && lastAction === "assigneeChange")
              }
            />
          );
        }}
      />

      <PingFloatingSelectInput
        options={statusOptions}
        selectedIndex={0}
        setSelectedIndex={onChangeStatus}
        placement="bottom-end"
        renderLabelElt={(_, isOpen) => {
          return (
            <PRToolbarButton
              label={selectedItem.workflow_status__name}
              iconName="directions"
              isActive={isOpen}
              isLoading={
                isLoadingChangeStatusRequest ||
                (isLoadingSubmissionList && lastAction === "submissionChange")
              }
            />
          );
        }}
      />

      <PRToolbarButton
        isDisabled={!mapsReady}
        label="Map"
        iconName="map"
        url={mapsReady ? selectedItem?.ping_maps?.url : null}
      />
      <PRToolbarButton
        label="Papertrail"
        iconName="receipt"
        url={`https://my.papertrailapp.com/groups/${PAPERTRAIL[import.meta.env.VITE_APP_ENV]}/events?q=${selectedItem?.global_request_id}`}
      />
      <PRToolbarButton
        label="Debug S3"
        iconName="folder"
        url={`https://us-east-1.console.aws.amazon.com/s3/buckets/${S3_RESULTS_BUCKET[import.meta.env.VITE_APP_ENV]}?region=us-east-1&bucketType=general&prefix=submissiondocuments/${selectedItem.id}/&showversions=false`}
      />
    </div>
  );
};

type PRToolbarButtonProps = {
  label: string;
  iconName: string;
  url?: string;
  isActive?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const PRToolbarButton: FC<PRToolbarButtonProps> = ({
  label,
  iconName,
  url,
  isActive,
  isLoading,
  isDisabled,
}) => {
  const appliedClasses = cx("PRToolbarButton", {
    "PRToolbarButton--IsActive": isActive,
    "PRToolbarButton--IsLoading": isLoading,
  });

  return url ? (
    <a className={appliedClasses} href={url} target="_blank">
      <PingMaterialIcon iconName={iconName} />
      <span>{label}</span>
    </a>
  ) : (
    <button className={appliedClasses} disabled={isDisabled}>
      <PingMaterialIcon iconName={iconName} />
      <span>{label}</span>
    </button>
  );
};
