export const PING_VISION_DEFAULT_GRID_PAGE_SIZE = 100;

export const PING_VISION_DEFAULT_FIELDS = [
  "created_time",
  "modified_time",
  "team__name",
  "division__short_name",
  "company__short_name",
  "source__source_type",
  "documents",
  "workflow_status__name",
  "actions",
  "division_id",
  "workflow_status_id",
  "ping_maps",
  "data_readiness_score",
  "data_readiness_notes",
  "team__name",
  "global_request_id",
  "client_ref",
  "extra_data",
  "inception_date",
  "expiration_date",
  "insured_name",
  "insured_street",
  "insured_street2",
  "insured_city",
  "insured_state",
  "insured_zip",
  "insured_fein",
  "insured_country",
  "insured_business_description",
  "from_email",
  "home_state",
  "broker",
  "broker_email",
  "broker_street",
  "broker_street2",
  "broker_city",
  "broker_state",
  "broker_zip",
  "broker_fein",
  "broker_country",
  "triage_rule_results",
  "triage_rules_overall_result",
  "days_until_inception",
  "client_name",
  "client_website",
];
